<span class="dialog-panel-close" mat-dialog-close>&times;</span>
<mat-card class="p-0">
    <mat-card-header class="px-4 px-lg-5 pt-5 pb-3 text-white justify-content-center">
        <mat-card-title class="text-center fw-bold big pb-2">
            @if (branchCodes.length > 1) {
                Vos codes agences
            } @else {
                Votre code agence est {{branchCodes[0]}} ?
            }
        </mat-card-title>
    </mat-card-header>
    @if (loading) {
        <mat-card-content class="p-5">
            <div class="py-5 my-4">
                <mat-spinner class="mx-auto" />
            </div>
        </mat-card-content>
    } @else if (accessSent && !loading) {
        <mat-card-content class="p-5">
            <h2 class="text-dark py-4 my-5 text-align-justify">
                Votre code agence a bien été mis à jour.
            </h2>
            <div class="row justify-content-center mt-4 mb-5">
                <div class="col text-center">
                    <button class="btn btn-black mt-2 text-nowrap" data-cy="close-dialog" (click)="closeDialog()">
                        Fermer
                    </button>
                </div>
            </div>
        </mat-card-content>
    } @else if (!accessSent && !loading) {

        <mat-card-content class="px-3 py-5">
            <div class="text-center mb-5">
                @if (branchCodes.length > 1) {
                    si vous avez modifié votre code d'agence, veuillez mettre à jour la liste ci-dessous :
                } @else {
                    Si vous avez changé d’agence, merci de saisir votre nouveau code agence.
                }
            </div>
            <form (ngSubmit)="updateBranchCode()" [formGroup]="formGroup"
                  class="container mb-5 px-5 mx-auto text-center">
                <div class="row my-3">
                    <div class="col px-3">
                        <mat-form-field>
                            <mat-chip-grid #chipGrid formControlName="branch">
                                @for (branchCode of formGroup.get('branch').value; track branchCode) {
                                    <mat-chip-row (removed)="removeBranchCode(branchCode)">
                                        {{branchCode}}
                                        <button matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip-row>
                                }
                                <input placeholder="Code agence ..."
                                       maxlength="5"
                                       id="branch"
                                       [matChipInputFor]="chipGrid"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                       [matChipInputAddOnBlur]="true"
                                       (matChipInputTokenEnd)="addBranchCode($event)"/>
                            </mat-chip-grid>
                            <mat-error>
                                Veuillez saisir votre code agence.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-4 mb-5">
                    <div class="col">
                        <button class="btn btn-black mt-2 text-nowrap"
                                data-cy="submit"
                                name="button"
                                type="submit">Mettre à jour
                        </button>
                    </div>
                </div>
                @if (showError) {
                    <div class="text-center mt-5 text-danger">
                        Une erreur inconnue s'est produite. Merci de contacter notre assistance.
                    </div>
                }
            </form>
        </mat-card-content>
    }
</mat-card>
