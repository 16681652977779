import {Reward} from "@isifid/core";

export class OperationStat {
    public operationId: number;
    public rewardStats: RewardStat[];
    public operationName = '';
    public startDate = '';
    public endDate = '';
    public rewardStatsFilter: RewardStat[];
}

export class RewardStat {
    public amount: number;
    public balance: number;
    public consumerStat: ConsumerStat;
    public expireAt = '';
    public giftUserStat: GiftUserStat;
    public offerId: number;
    public operationId: number;
    public rewardId: number;
    public rewardProperties = {};
    public rewardTypeId: number;
    public rewardedAt = '';
}

export class GiftUserStat {
    public branchCode = '';
    public giftUserEmail = '';
    public giftUserFirstName = '';
    public giftUserId = '';
    public giftUserLastName = '';
    public giftUserLevelId = '';
}

export class ConsumerStat {
    public birthDate = '';
    public email = '';
    public externalId = '';
    public firstName = '';
    public gender: string = '';
    public id = -1;
    public lastName = '';
    public mobile = '';
    public branchCode = '';
    public giftUserId = -1;
    public consumerType = '';
    public expectedSponsoredType = '';
}

export class SponsorshipStat {
    public operationId: number;
    public startDate = '';
    public endDate = '';
    public sponsorStats: SponsorStat[];
    public rewardedSponsorStats: RewardedSponsorStat[];
    public sponsoredStats: SponsoredStat[];
}

export class SponsorStat {
    public giftUserStat: GiftUserStat;
    public consumerStat: ConsumerStat;
    public sponsorCode = '';
    public sponsorCodeSentAt = '';
    public sponsoredCount = -1;
    public source = '';
}

export class RewardedSponsorStat {
    public giftUserStat: GiftUserStat;
    public consumerStat: ConsumerStat;
    public sponsorCode = '';
    public sponsorCodeSentAt = '';
    public rewardCreatedAt = '';
    public source = '';
}

export class SponsoredStat {
    public amountRewarded = -1;
    public consumerStat: ConsumerStat;
    public giftUserStat: GiftUserStat;
    public rewardedAt = '';
    public sponsorCode = '';
    public sponsorEmail: string = '';
    public sponsorExternalId: string = '';
    public sponsoredSponsorCode: string = '';
}

export class StatsDisplay extends GiftUserStat {
    public email = '';
    public externalId = '';
    public firstName = '';
    public id = -1;
    public lastName = '';
    public mobile = '';
    public birthDate = '';
    public amount = 0;
    public rewardedAt = '';
    public expireAt = '';
    public label = '';
    public giftUserStat: GiftUserStat;
    public consumerStat: ConsumerStat;
    public offerId: number;
    public operationId: number;
    public rewardProperties = {};
    public rewardTypeId: number;
    public branchName: string = '';
}
