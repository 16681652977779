import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Consumer, GiftUser, MsConsumersService, RewardRequestBatch} from '@isifid/core';
import {GiftService} from './gift.service';
import {OperationsService} from './operations.service';
import {GiftUserService} from './gift-user.service';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ConsumerService {
    private giftUser: GiftUser;

    constructor(
        private readonly msConsumersService: MsConsumersService,
        private readonly operationsService: OperationsService,
        private readonly giftService: GiftService,
        private readonly giftUserService: GiftUserService
    ) {
        this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => this.giftUser = s);
    }

    public getGender(gender): string {
        if (!gender) return '';
        else if (gender === 1 || gender === '1') return 'M.';
        else if (gender === 2 || gender === 3 || gender === '2' || gender === '3') return 'Mme';
        else return '';
    }

    public rewardOne(consumer, offer, rewardAttributes): Observable<any> {
        const giftUserId = this.giftUser.id;
        const branchCode = this.giftService.convertBranchCodeToString(this.giftUser?.branchList?.[0]);
        const operation = this.operationsService.getOperations(true).find(o => o.id === offer.operationId);

        let sendEmail = !!consumer.email;
        let sendSms = !!consumer.mobile;

        // If reward in cash don't send communications
        if (operation.rewardTypeId === 10) {
            sendEmail = false;
            sendSms = false;
        }

        // Allow attributes such as sponsor code etc. to be set
        const consumerToReward = {...consumer, rewardAttributes: rewardAttributes};
        const data: RewardRequestBatch = {
            offerId: offer.id,
            consumersToReward: [consumerToReward],
            sendEmail: sendEmail,
            sendSms: sendSms,
            giftUserId: giftUserId,
            branchCode: branchCode
        };

        return new Observable(o => {
            this.msConsumersService.createBatchReward(data).subscribe({
                next: (batchReward) => {
                    o.next(batchReward);
                    o.complete();
                }, error: () => o.error()
            });
        });
    }

    getConsumerByExternalId(externalId): Observable<any> {
        return new Observable(o => {
            this.msConsumersService.getAll([], {externalId, clientId: this.giftService.client.id}).subscribe({
                next: (consumer) => {
                    o.next(consumer);
                    o.complete();
                }, error: () => o.error()
            });
        });
    }

    getConsumerByMobile(mobile): Observable<any> {
        return new Observable(o => {
            this.msConsumersService.getAll([], {mobile, clientId: this.giftService.client.id}).subscribe({
                next: (consumer) => {
                    o.next(consumer);
                    o.complete();
                }, error: () => o.error()
            });
        });
    }

    searchConsumer(consumer): Observable<Array<Consumer>> {
        const data = {
            mobile: consumer.mobile,
            externalId: '',
            clientId: this.giftService.client.id
        };
        if (this.giftService.getConfigurationValue('externalIdRequiredForSponsor')) {
            data.mobile = '';
            data.externalId = consumer.externalId;
        }

        return new Observable(o => {
            this.msConsumersService.getAll([], data).subscribe({
                next: (consumers) => {
                    o.next(consumers);
                    o.complete();
                }, error: () => o.error()
            });
        });
    }

    // Create consumer
    createConsumer(consumer): Observable<Consumer> {
        const externalIdRequiredForSponsor = !!this.giftService.getConfigurationValue('externalIdRequiredForSponsor');
        return new Observable(o => {
            this.msConsumersService.createConsumer(consumer, externalIdRequiredForSponsor).subscribe({
                next: (consumer) => {
                    if (consumer?.body) o.next(consumer.body);
                    else o.next(consumer);
                    o.complete();
                }, error: () => console.error('error while creating consumer')
            });
        });
    }
}

